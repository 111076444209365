.banner {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 140, 115);
  color: #fff;
  padding: 5px 20px;
  font-size: 16px;
}

.banner a {
  color: inherit;
  font-weight: bold;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  alignitems: center;
  height: 70px;
}

.content {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 2;
}
